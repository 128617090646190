<template>
	<modal name="ClvModalUsersQuals" class="ClvModalUsersQuals" :width="600" :height="'auto'" :adaptive="true" :scrollable="true" :clickToClose="true" @before-open="beforeOpen">
		<div class="clv_modal">
			<div class="clv_modal_open_loading" v-if="clvIsOpenLoading"><div class="clv_loading"></div></div>
			<div class="clv_modal_open" v-else>
				<div class="clv_modal_head">
					<div class="clv_modal_close" @click="close"><font-awesome-icon :icon="['fas', 'times']" /></div>
					<div class="clv_modal_title">
						<span v-if="clvType == 'getUserQuals'">Статистика Qual для CLV ID: {{ clvId }}</span>
					</div>
				</div>
				<div class="clv_modal_body">
					<div class="clv_modal_info">
						<div v-if="clvType == 'getUserQuals'">
							<highcharts class="clv_chart" :options="clvChartStatistics1"></highcharts>
							<highcharts class="clv_chart" :options="clvChartStatistics2"></highcharts>
						</div>
					</div>
					<div class="clv_modal_alert" v-html="clvAlert"></div>
					<div class="clv_modal_loading" :class="{ hide: !clvIsLoading }"><img src="@/assets/images/loading.gif"></div>
					<div class="clv_modal_buttons" :class="{ hide: clvIsLoading }">
						<button type="button" class="clv_button common" @click="close">Закрыть</button>
					</div>
				</div>
			</div>
		</div>
	</modal>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			clvTemp: '',
			clvAlert: '',
			clvId: 0,
			clvType: '',
			clvIndex: 0,
			clvChartStatistics1: {},
			clvChartStatistics2: {},
			clvChartLimitOffset: 0,
			clvChartLimitCount: 30,
			clvIsOpenLoading: false,
			clvIsLoading: false,
			clvIsError: false
		}),
		methods: {
			beforeOpen: function(event) {

				this.clvTemp = '';
				this.resetAlerts();
				this.clvId = event.params.id;
				this.clvType = event.params.type;
				this.clvIndex = event.params.index;
				this.clvChartStatistics1 = {};
				this.clvChartStatistics2 = {};
				this.clvChartLimitOffset = 0;
				this.clvChartLimitCount = 30;
				this.clvIsOpenLoading = false;
				this.clvIsLoading = false;
				this.clvIsError = false;

				if (this.clvType == 'getUserQuals') { this.axios(); }
			},
			resetAlerts: function() {
				this.clvAlert = '';
			},
			getError: function(i) {

				this.clvIsError = true;

				if      (i == 1) { this.clvTemp = 'Необходимо указать CLV ID трейдера.'; }
				else if (i == 2) { this.clvTemp = 'Необходимо указать верный CLV ID трейдера.'; }
				else             { this.clvTemp = 'Внутренняя ошибка сервера.'; }

				return '<div class="alert"><strong>Ошибка!</strong> ' + this.clvTemp + '</div>';
			},
			axios: function() {

				var _this = this;
				if (localStorage.getItem('clvToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.clvApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('clvToken') }
					};

					_this.resetAlerts();
					_this.clvIsError = false;

					if (_this.clvType == 'getUserQuals') {
						_this.clvIsOpenLoading = true;
						config.url = '/v2/admin/account/users/quals';
						config.params = { user_id: _this.clvId, limit_offset: _this.clvChartLimitOffset, limit_count: _this.clvChartLimitCount };
					}

					if (!_this.clvIsError) {
						axios(config).then(function(data) {
							if (data.status == 200) {
								if (_this.clvType == 'getUserQuals') {
									_this.clvChartStatistics1 = {
										title: { text: 'История изменения квалификации' },
										series: [{ name: 'CLV Qual', color: '#7CB5EC', data: [] }]
									};
									_this.clvChartStatistics2 = {
										title: { text: 'История изменения количества проанализированных интервалов' },
										series: [{ name: 'Количество интервалов', color: '#F45B5B', data: [] }]
									};
									if (data.data.data.length) {
										var statistics = data.data.data.reverse();
										for (var s in statistics) {
											var s_created_at = parseInt(statistics[s].created_at) * 1000;
											_this.clvChartStatistics1.series[0].data.push([s_created_at, parseFloat(statistics[s].weight)]);
											_this.clvChartStatistics2.series[0].data.push([s_created_at, parseInt(statistics[s].intervals)]);
										}
									}
								}
							}
						}).catch(function(data) {
							if (data.response !== undefined) {
								if (data.response.status == 400) {
									if (_this.clvType == 'getUserQuals') {
										if      (data.response.data.error == 'USER_ID_IS_EMPTY') { _this.clvAlert = _this.getError(1); }
										else if (data.response.data.error == 'USER_ID_NOT_VALID') { _this.clvAlert = _this.getError(2); }
									}
								}
								else if (data.response.status == 401) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 403) { _this.$parent.$parent.$parent.exit(); }
							}
							if (!_this.clvIsError) {
								_this.clvAlert = _this.getError(0);
							}
						}).finally(function() {
							_this.clvIsOpenLoading = false;
							_this.clvIsLoading = false;
						});
					}
				}
				else {
					_this.$parent.$parent.$parent.exit();
				}
			},
			close: function() {
				this.$modal.hide('ClvModalUsersQuals');
			}
		}
	}
</script>
